<template>
    <div class="flex flex-col justify-center sm:px-6 lg:px-8 ccss--user-transactions my-transactions"
         :class="{'no-transactions': !transactions.length }"
    >
        <div class="mx-auto w-full max-w-2xl">
            <h2 class="text-center text-3xl font-extrabold">
                {{ $t('mytransactions.title') }}
            </h2>
        </div>

        <div class="">
            <router-link :to="{ name: 'user.myaccount' }">
                <a class="link-arrow-left account-link-orders mt-8">{{ $t('myaccount') }}</a>
            </router-link>
            <div class="mx-auto w-full max-w-xl orders-list" v-if="transactions.length">
                <div class="bg-white py-8 px-4 shadow sm:rounded-sm sm:px-10">

                    <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                        <div class="align-middle inline-block min-w-full  overflow-hidden border-b border-color">
                            <table class="min-w-full">
                                <thead>
                                    <tr>
                                        <th class="px-6 py-3 border-b border-color text-left text-sm leading-4 font-medium text-main uppercase tracking-wider">
                                            {{ $t('fields.number') }}
                                        </th>
                                        <th class="hidden sm:table-cell px-6 py-3 border-b border-color text-left text-sm leading-4 font-medium text-main uppercase tracking-wider">
                                            {{ $t('fields.date') }}
                                        </th>
                                        <th class="hidden sm:table-cell px-6 py-3 border-b border-color text-left text-sm leading-4 font-medium text-main uppercase tracking-wider">
                                            {{ $t('fields.time') }}
                                        </th>
                                        <th class="px-6 py-3 border-b border-color text-left text-sm leading-4 font-medium text-main text-right uppercase tracking-wider">
                                            {{ $t('fields.total') }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(transaction, index) in transactions" :key="index">
                                        <td class="cursor-pointer px-6 py-4 whitespace-no-wrap text-base leading-5 font-medium text-main">
                                            <router-link
                                                :to="{ name: 'user.transaction', params: {transaction_id: transaction.id }}"
                                            >
                                                {{ transaction.attributes.transactionnumber }}
                                            </router-link>
                                        </td>
                                        <td class="hidden sm:table-cell px-6 py-4 whitespace-no-wrap text-base leading-5 font-medium text-main">
                                            {{ transaction.attributes.date }}
                                        </td>
                                        <td class="hidden sm:table-cell px-6 py-4 whitespace-no-wrap text-base leading-5 font-medium text-main">
                                            {{ transaction.attributes.time }}
                                            <!-- {{  $transaction->completed_at->format('H:i') }} -->
                                        </td>
                                        <td class="px-6 py-4 whitespace-no-wrap text-base leading-5 font-medium text-main text-right">
                                            {{ transaction.attributes.total }}
                                        </td>
                                    </tr>
                                    <!-- @endforeach -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="no-orders">
                <div>{{ $t('mytransactions.no-transactions') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
import * as _api from '../lib/api';

export default {
    name: 'UserTransactions',
    props: ['shortList'],
    data () {
        return {
            transactions: [],
        };
    },
    async mounted () {
        this.transactions = await _api.get('customer/transactions', {}, false);

        if (this.shortList === true && this.transactions && this.transactions.length > 2) {
            this.transactions = this.transactions.slice(0, 2);
        }
    },

    methods: {},
};
</script>
